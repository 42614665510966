import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Convert from 'color-convert';
import { RiSortAsc, RiSortDesc, RiCloseCircleFill, RiHashtag, RiSearchLine, RiSipLine, RiLayoutGridFill, RiMenuFill, RiCreativeCommonsLine, RiCreativeCommonsByLine, RiCreativeCommonsNcLine, RiCreativeCommonsSaLine } from 'react-icons/ri';
import { SketchPicker } from 'react-color';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';

import { colorData } from './ntc';
import { ReactComponent as Logo } from './../assets/img/logo.svg';
import withRouter from './../helper/withrouter';

class App extends Component{
  constructor(){
    super();
    
    this.state = {
      listColor: [],
      theColor: [],
      toggle: '',
      backgroundToggle: false,
      background: '#FFF',
      sort: true,
      thumb: true,
      search: '',
      searchVal: '',
      searchToggle: false,
      result: ''
    }

    this.regexHex = new RegExp(/^(?:[0-9a-f]{3}){1,2}$/i);

    this.copy = 2022;
    this.today = new Date();
    
    this.wrapperRef = React.createRef();
    this.buttonRef = React.createRef();
    this.searchRef = React.createRef();
    this.srchbtnRef = React.createRef();

    this.inputChange = this.inputChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.srchToggle = this.srchToggle.bind(this);
    this.searchChange = this.searchChange.bind(this);
    
    this.bgToggle = this.bgToggle.bind(this);
    this.bgChange = this.bgChange.bind(this);
    this.sort = this.sort.bind(this);
    this.view = this.view.bind(this);
    this.copas = this.copas.bind(this);
    this.detail = this.detail.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount(){
    document.addEventListener('mousedown', this.handleClickOutside);

    let dataColor = [];

    colorData.forEach((value, index) => {
      // if(index < 36){
        dataColor.push(this.allValue(value[0], value[1]));
      // }else{
      //   return true;
      // }
    });

    this.setState({
      listColor: dataColor,
      theColor: dataColor
    });

    if(this.props.location.hash){
      this.setState({ search: this.props.location.hash.replace('#', '') });
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.search !== prevState.search){
      if(this.state.search !== ''){
        if(this.regexHex.test(this.state.search)){
          let color = this.state.search.toUpperCase();
        
          if(color.length > 2 && color.length < 7){
            if(color.length === 3){
              color = '#' + color.substr(0, 1) + color.substr(0, 1) + color.substr(1, 1) + color.substr(1, 1) + color.substr(2, 1) + color.substr(2, 1);
            }else if(color.length === 6){
              color = '#' + color;
            }else{
              color = '';
            }
          }else{
            color = '';
          }
          
          if(color !== ''){
            const theVal = color.replace('#', '');
  
            this.setState({ searchVal: this.allValue(theVal, '') });
            this.props.navigate({  pathname: '/', hash: theVal});
  
            let rgb = Convert.hex.rgb(color),
                r = rgb[0], g = rgb[1], b = rgb[2],
                hsl = Convert.hex.hsl(color),
                h = hsl[0], s = hsl[1], l = hsl[2],
                ndf1 = 0, ndf2 = 0, ndf = 0,
                cl = -1, df = -1,
                ntc = [...this.state.listColor];
  
            for(var i = 0; i < ntc.length; i++){
              const ntcIndex = ntc[i];
  
              if(color === ntcIndex.hex){
                this.setState({ result: ntcIndex });
                return true;
              }
              
              ndf1 = Math.pow(r - ntcIndex.r, 2) + Math.pow(g - ntcIndex.g, 2) + Math.pow(b - ntcIndex.b, 2);
              ndf2 = Math.pow(h - ntcIndex.h, 2) + Math.pow(s - ntcIndex.s, 2) + Math.pow(l - ntcIndex.l, 2);
              ndf = ndf1 + ndf2 * 2;
  
              if(df < 0 || df > ndf){
                df = ndf;
                cl = i;
              }
            }
  
            this.setState({ result: ntc[cl] });
          }else{
            this.setState({ result: '' });
          }
        }else{
          const dataColor = this.state.listColor.filter(x => x.name.toLowerCase().includes(this.state.search.toLowerCase()));
          
          this.setState({ theColor: dataColor });
        }
      }else{
        this.setState({
          theColor: this.state.listColor,
          result: ''
        });
      }
    }

    if(this.state.result !== prevState.result){
      if(this.state.result === ''){
        this.setState({
          toggle: '',
          searchVal: ''
        });
        this.props.navigate({  pathname: '/', hash: ''});
      }
    }
  }

  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  inputChange(event){
    this.setState({ [event.target.name]: event.target.value });
  }

  clearSearch(){
    this.setState({ search: '' });
  }

  srchToggle(){
    this.setState({ searchToggle: !this.state.searchToggle });
  }

  searchChange(color){
    this.setState({ search: (color.hex.replace('#', '')).toUpperCase() });
  }

  bgToggle(){
    this.setState({ backgroundToggle: !this.state.backgroundToggle });
  }

  bgChange(color){
    this.setState({ background: color.hex.toUpperCase() });
  }

  sort(){
    let dataColor = [...this.state.theColor];

    dataColor.reverse();

    this.setState({
      theColor: dataColor,
      sort: !this.state.sort
    });
  }

  view(){
    this.setState({ thumb: !this.state.thumb });
  }

  copas(event){
    event.stopPropagation();
    
    navigator.clipboard.writeText(event.target.innerText);
    
    toast(<>Copied <strong>{event.target.innerText}</strong> value to clipboard</>);
  }

  detail(event){
    if(this.state.result === ''){
      let minTop = 0,
          delay = 250;

      if(this.state.toggle === event.target.id){
        this.setState({ toggle: '' });
        minTop = 70;
        delay = 300;
      }else{
        this.setState({ toggle: event.target.id });
        minTop = 90;
        delay = 250;
      }

      setTimeout(() => {
        // console.log(document.getElementById(event.target.id).offsetTop);
        window.scrollTo({ top: document.getElementById(event.target.id).offsetTop - minTop, behavior: 'smooth' });
        // document.getElementById(event.target.id).scrollIntoView({ top: -100 + 'px', behavior: 'smooth', block: 'start' });
      }, delay);
    }
  }

  handleClickOutside(event){
    if(
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target) &&
      this.buttonRef.current &&
      !this.buttonRef.current.contains(event.target)
    ){
        this.setState({ backgroundToggle: false });
    }

    if(
      this.searchRef.current &&
      !this.searchRef.current.contains(event.target) &&
      this.srchbtnRef.current &&
      !this.srchbtnRef.current.contains(event.target)
    ){
        this.setState({ searchToggle: false });
    }
  }

  allValue(value, name){
    const convertHex = Convert.hex,
          rgb = convertHex.rgb(value),
          rgbRed = parseFloat(((rgb[0] / 255) * 100).toFixed(0)),
          rgbGreen = parseFloat(((rgb[1] / 255) * 100).toFixed(0)),
          rgbBlue = parseFloat(((rgb[2] / 255) * 100).toFixed(0)),
          hsl = convertHex.hsl(value),
          hsv = convertHex.hsv(value),
          cmyk = convertHex.cmyk(value),
          cmykCyan = parseFloat(((cmyk[0] / 255) * 100).toFixed(0)),
          cmykMagenta = parseFloat(((cmyk[1] / 255) * 100).toFixed(0)),
          cmykYellow = parseFloat(((cmyk[2] / 255) * 100).toFixed(0)),
          cmykBlack = parseFloat(((cmyk[3] / 255) * 100).toFixed(0)),
          apple = convertHex.apple(value),
          hcg = convertHex.hcg(value),
          hwb = convertHex.hwb(value),
          lab = convertHex.lab(value),
          lch = convertHex.lch(value),
          xyz = convertHex.xyz(value);

    // if(value === '000090'){
    //   console.log(rgb, hsl);
    // }

    return {
      name: name || 'Your Color',
      hex: '#' + value,
      rgb: 'rgb(' + rgb[0] + ', ' + rgb[1] + ', ' + rgb[2] + ')',
      r: rgb[0],
      g: rgb[1],
      b: rgb[2],
      h: hsl[0],
      s: hsl[1],
      l: hsl[2],
      key: convertHex.keyword(value),
      ansi16: convertHex.ansi16(value),
      ansi256: convertHex.ansi256(value),
      gray: convertHex.gray(value),
      hsl: 'hsl(' + hsl[0] + ', ' + hsl[1] + '%, ' + hsl[2] + '%)',
      hsv: 'hsv(' + hsv[0] + ', ' + hsv[1] + '%, ' + hsv[2] + '%)',
      cmyk: 'cmyk(' + cmyk[0] + ', ' + cmyk[1] + ', ' + cmyk[2] + ', ' + cmyk[3] + ')',
      cyan: cmykCyan,
      magenta: cmykMagenta,
      yellow: cmykYellow,
      black: cmykBlack,
      totalCmyk: cmykCyan + cmykMagenta + cmykYellow + cmykBlack,
      apple: 'apple(' + apple[0] + ', ' + apple[1] + ', ' + apple[2] + ')',
      hcg: 'hcg(' + hcg[0] + ', ' + hcg[1] + ', ' + hcg[2] + ')',
      hwb: 'hwb(' + hwb[0] + ', ' + hwb[1] + ', ' + hwb[2] + ')',
      lab: 'lab(' + lab[0] + ', ' + lab[1] + ', ' + lab[2] + ')',
      lch: 'lch(' + lch[0] + ', ' + lch[1] + ', ' + lch[2] + ')',
      xyz: 'xyz(' + xyz[0] + ', ' + xyz[1] + ', ' + xyz[2] + ')',
      hue: ((hsl[0] / 360) * 100).toFixed(0),
      bright: 100 - cmyk[3],
      red: rgbRed,
      green: rgbGreen,
      blue: rgbBlue,
      totalRgb: rgbRed + rgbGreen + rgbBlue,
    };
  }

  renderColorBox(value){
    return(
      <div
        className={`color-box ${this.state.result ? 'comparison' : ''}`}
        onClick={this.detail}
        style={{backgroundColor: value.hex}}
        id={value.hex}
      >
        <div className="detail-box">
          <ul className="pri-info">
            <li className="naming" onClick={this.copas}>
              {value.name}
            </li>
            <li onClick={this.copas}>
              {value.hex}
            </li>
            <li onClick={this.copas}>
              {value.rgb}
            </li>
          </ul>
          {this.state.toggle === value.hex || this.state.result !== '' ? (
            <>
              <ul className="sec-info">
                <li onClick={this.copas}>
                  <span>ANSI-16</span>
                  {value.ansi16}
                </li>
                <li onClick={this.copas}>
                  <span>ANSI-256</span>
                  {value.ansi256}
                </li>
                <li onClick={this.copas}>
                  <span>Apple</span>
                  {value.apple}
                </li>
                <li onClick={this.copas}>
                  <span>Closest CSS Keyword</span>
                  <div className="box-css" style={{backgroundColor: value.key}}></div>{value.key}
                </li>
                <li onClick={this.copas}>
                  <span>CMYK</span>
                  {value.cmyk}
                </li>
                <li onClick={this.copas}>
                  <span>Gray</span>
                  {value.gray}
                </li>
                <li onClick={this.copas}>
                  <span>HCG</span>
                  {value.hcg}
                </li>
                <li onClick={this.copas}>
                  <span>HSL</span>
                  {value.hsl}
                </li>
                <li onClick={this.copas}>
                  <span>HSV</span>
                  {value.hsv}
                </li>
                <li onClick={this.copas}>
                  <span>HWB</span>
                  {value.hwb}
                </li>
                <li onClick={this.copas}>
                  <span>LAB</span>
                  {value.lab}
                </li>
                <li>
                  <span>LCH</span>
                  {value.lch}
                </li>
                <li onClick={this.copas}>
                  <span>XYZ</span>
                  {value.xyz}
                </li>
              </ul>
              <ul className="sec-info">
                <li className="lay2">
                  <span>Hue <strong>{value.hue}%</strong></span>
                  <div className="darkpicker huepicker">
                    <div className="pointer" style={{left: (value.hue) + '%'}}></div>
                  </div>
                </li>
                <li className="lay2">
                  <span>Brightness <strong>{value.bright}%</strong></span>
                  <div className="darkpicker">
                    <div className="pointer" style={{left: (value.bright) + '%'}}></div>
                  </div>
                </li>
                <li className="lay2">
                  <ul className="colorchart rgb">
                    <li>
                      RGB - Red <strong>{value.red}%</strong>
                      <div>
                        <span style={{background: 'red', width: value.red + '%'}}></span>
                      </div>
                    </li>
                    <li>
                      RGB - Green <strong>{value.green}%</strong>
                      <div>
                        <span style={{background: 'green', width: value.green + '%'}}></span>
                      </div>
                    </li>
                    <li>
                      RGB - Blue <strong>{value.blue}%</strong>
                      <div>
                        <span style={{background: 'blue', width: value.blue + '%'}}></span>
                      </div>
                    </li>
                    <li>
                      RGB - Scheme
                      <div className="multi">
                        <span style={{ background: 'red', width: ((value.red / value.totalRgb) * 100) + '%' }}></span>
                        <span style={{ background: 'green', width: ((value.green / value.totalRgb) * 100) + '%' }}></span>
                        <span style={{ background: 'blue', width: ((value.blue / value.totalRgb) * 100) + '%' }}></span>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="lay2">
                  <ul className="colorchart cmyk">
                    <li>
                      CMYK - Cyan <strong>{value.cyan}%</strong>
                      <div>
                        <span style={{background: 'cyan', width: value.cyan + '%'}}></span>
                      </div>
                    </li>
                    <li>
                      CMYK - Magenta <strong>{value.magenta}%</strong>
                      <div>
                        <span style={{background: 'magenta', width: value.magenta + '%'}}></span>
                      </div>
                    </li>
                    <li>
                      CMYK - Yellow <strong>{value.yellow}%</strong>
                      <div>
                        <span style={{background: 'yellow', width: value.yellow + '%'}}></span>
                      </div>
                    </li>
                    <li>
                      CMYK - Black <strong>{value.black}%</strong>
                      <div>
                        <span style={{background: 'black', width: value.black + '%'}}></span>
                      </div>
                    </li>
                    <li>
                      <span>CMYK - Scheme</span>
                      <div className="multi">
                        <span style={{ background: 'cyan', width: ((value.cyan / value.totalCmyk) * 100) + '%' }}></span>
                        <span style={{ background: 'magenta', width: ((value.magenta / value.totalCmyk) * 100) + '%' }}></span>
                        <span style={{ background: 'yellow', width: ((value.yellow / value.totalCmyk) * 100) + '%' }}></span>
                        <span style={{ background: 'black', width: ((value.black / value.totalCmyk) * 100) + '%' }}></span>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </>
          ) : ''}
        </div>
      </div>
    );
  }
  
  render(){
    // console.log(this.state.toggle);

    return(
      <div className="bg" style={{background: this.state.background}}>
        <Helmet>
          <title>ColorName {this.state.searchVal ? this.state.searchVal.hex : this.state.toggle ? this.state.toggle : ''}</title>
        </Helmet>

        <header>
          <ul>
            <li>
              <Link to="/">
                {/* ColorName */}
                <Logo />
              </Link>
              <div className="wrap-search">
                <button type="button" onClick={this.srchToggle} className="picker" ref={this.srchbtnRef}>
                  <RiSipLine />
                </button>
                {this.state.search ? (
                  <button type="button" onClick={this.clearSearch} className="clear"><RiCloseCircleFill /></button>
                ) : ''}
                <input type="text" name="search" placeholder="Search hex or color name here..." value={this.state.search} onChange={this.inputChange} className={this.state.search ? 'has-val' : ''} />
                <span className="hash">
                  {this.state.search ? (<RiSearchLine />) : (<RiHashtag />)}
                </span>
              </div>
              {this.state.searchToggle ? (
                <div className="colorpicker" ref={this.searchRef}>
                  <SketchPicker
                    disableAlpha
                    color={ this.state.search }
                    onChangeComplete={ this.searchChange }
                    presetColors={[]}
                  />
                </div>
              ) : ''}
            </li>
            <li>
              <button onClick={this.bgToggle} ref={this.buttonRef}>
                <span>Background</span>
                <div className="preview" style={{background: this.state.background}}></div>
                {this.state.background}
              </button>
              {this.state.backgroundToggle ? (
                <div className="colorpicker" ref={this.wrapperRef}>
                  <SketchPicker
                    disableAlpha
                    color={ this.state.background }
                    onChangeComplete={ this.bgChange }
                    presetColors={[]}
                  />
                </div>
              ) : ''}
            </li>
            <li>
              <button onClick={this.sort}>
                <span>Sort</span>
                {this.state.sort ? (
                  <>
                    <RiSortDesc />Dark - Light
                  </>
                ) : (
                  <>
                    <RiSortAsc />Light - Dark
                  </>
                )}
              </button>
            </li>
            <li>
              <button onClick={this.view}>
                <span>View</span>
                {this.state.thumb ? (
                  <>
                    <RiLayoutGridFill />Thumbnail
                  </>
                ) : (
                  <>
                    <RiMenuFill />List
                  </>
                )}
              </button>
            </li>
          </ul>
        </header>

        <div className="pad-top"></div>
        
        {this.state.result ? (
          <>
            <ul className="list-color search-result">
              <li className="big">
                {this.renderColorBox(this.state.searchVal)}
              </li>
            </ul>
            <ul className="list-color">
              <li className="big">
                {this.renderColorBox(this.state.result)}
              </li>
            </ul>
          </>
        ) : this.state.theColor.length ? (
          <ul className={`list-color ${this.state.thumb ? '' : 'list'}`}>
            {this.state.theColor.map((value, index) => (
              <li key={value.hex} className={this.state.toggle === value.hex ? 'big': ''}>
                {this.renderColorBox(value)}
              </li>
            ))}
          </ul>
        ) : (
          <div className="empty">
            <RiSearchLine />
            Search not find any color for "<strong>{this.state.search}</strong>" keyword, please change or clear the search keyword.
          </div>
        )}

        <footer>
          {/* Copyrights &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} <Link to="/">ColorName</Link> by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a>. All Rights Reserved. */}
          <a href="/">ColorName</a> &copy; {this.today.getFullYear() !== this.copy ? this.copy + ' - ' + this.today.getFullYear() : this.copy} by <a href="https://nabilamerthabit.com" target="_blank" rel="noreferrer">Nabil Amer Thabit</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer">CreativeCommons BY-NC-SA 4.0 <RiCreativeCommonsLine /> <RiCreativeCommonsByLine /> <RiCreativeCommonsNcLine /> <RiCreativeCommonsSaLine /></a>
          <div>Color data from <a target="_blank" rel="noreferrer" href="https://chir.ag/projects/name-that-color/">Name that Color</a>. We do not guarantee the accuracy or completeness of the information provided.</div>
        </footer>
      </div>
    );
  }
}

export default withRouter(App);
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default function withRouter( Child ) {
    return ( props ) => {
        const   location = useLocation(),
                navigate = useNavigate(),
                params = useParams();

        return <Child { ...props } navigate={ navigate } location={ location } params={ params } />;
    }
}